import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate, StaticQuery } from "gatsby";
import useStore from "../../context/StoreContext";
import { getProduct } from "../../services/products";
import paragraphs from "lines-to-paragraphs";

import TextField from '@mui/material/TextField';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageSlider from "../image/ImageSlider"; // requires a loader


const AtelierReservation = (props)  => {
  const { cart } = useStore()

  const { data, product, id } = props

  const [slides, setSlides] = useState([])

  const productDynamic = data.allShopifyProduct.edges[0].node

  const { addVariantToCart } = useStore()

  const [placeLeft, setPlaceLeft] = useState(parseInt(productDynamic.totalInventory))
  const [placeToBook,setPlaceToBook] = useState(0)
  const [isFull,setIsFull] = useState(true)

  const [placesAddedToCart, setPlacesAddedToCart] = useState(false)
  const [placeAdded, setPlaceAdded] = useState(0)

  const [comment, setComment] = useState("")

  const handleChangeComment = (event) => {
    // 👇 Get input value from "event"
    setComment(event.target.value);
  };



  React.useEffect(() => {
    getProductQuantity().then(() => {
      handleProductInCart()
    })
  }, [cart])

  React.useEffect(() => {
    let images = [];
    product.media.map((img) => {
      if(img.preview.image.originalSrc) {
        images.push({title : product.title, image : img.preview.image.originalSrc, gatsbyImage: img.preview.image.gatsbyImageData, description : img.alt})
      }
    })
    setSlides(images)
  }, [])

  const handleProductInCart = () => {
    cart.map((productFromCart) => {
      if(productFromCart.product.title === product.title) {
        setPlaceLeft((parseInt(productDynamic.totalInventory) - productFromCart.quantity).toString())
        if(productDynamic.totalInventory - productFromCart.quantity <= 0) {
          setIsFull(true)
        }
      }
    })
  }

  const getProductQuantity = async () => {
    await getProduct(product.shopifyId).then((data) => {
      setPlaceLeft(data.data.product.variants[0].inventory_quantity)
      if(data.data.product.variants[0].inventory_quantity == 0) {
        setPlaceToBook(0)
        setIsFull(true)
      } else {
        setPlaceToBook(1)
        setIsFull(false)
      }
    })
  }

  const addToCart = (e) => {
    if(placeToBook > 0) {
      addVariantToCart(productDynamic, placeToBook,0, comment).then(() => {
        setPlacesAddedToCart(true)
        setPlaceAdded(placeToBook)
      })
    }
  }

  const handleInputChange = (event) => {
    const value = Number(event.target.value);
    setPlaceToBook(value);
    if(value > placeLeft) {
      setPlaceToBook(placeLeft);
    }
  };

  const imageStyle = { borderRadius: '0px', margin: 'auto',  maxHeight : "400px", width:"100%", objectFit: "contain", justifyContent: "center", alignItems: "center", alignSelf: "center"   }

  return (
    <>
      <div className="columns">
        <div className="column is-6" style={{minHeight : "100%"}}>
            <ImageSlider slides={slides} imageStyle={imageStyle} showDescription={true} autoPlay={true}/>
        </div>
        <div className="column is-6 is-flex-direction-column is-flex">
          <div>
            <h3>{product.title}</h3>
          </div>
          <div style={{marginBottom: 0}}>
            <div dangerouslySetInnerHTML={{ __html: paragraphs(productDynamic.descriptionHtml) }} style={{ marginTop: 10 }}/>

            {!product.tags.includes("externe") &&
            <>
              {placeLeft > 0 ?
                <h5>{placeLeft} place(s) restante(s)</h5> :
                <h5>COMPLET</h5>
              }
            </>
            }
          </div>

          {!product.tags.includes("externe") &&
          <div className="">
            <div className="number-input align--center-center mt--1" style={{ marginBottom : "10px"}}>
                    <span className="ml--1 mr--1" data-testid="count-status">
                      <label> Quantité :
                          <input style={{ width: "60px", marginLeft: "10px", fontSize : "1em", textAlign : "center"}}
                                 type={"number"}
                                 max={placeLeft}
                                 min={0}
                                 value={placeToBook}
                                 disabled={isFull}
                                 onChange={handleInputChange}
                                 className="input-quantity"
                                 data-testid="nb-capacitor-to-reveal"
                          />
                        {placesAddedToCart &&
                        <p className="is-size-6" style={{ color: "#085229", fontStyle: "italic" }}> {placeAdded} place{placeAdded > 1 ? "s" : null} ajouté au panier !</p>
                        }
                        </label>
                    </span>
            </div>
          </div>
          }


          {product.title.toLowerCase().startsWith("apero") || product.title.toLowerCase().startsWith("apéro") &&
          <>
            <label style={{ marginBottom : "10px"}}> Avez-vous des intolérances ou allergies alimentaires à nous spécifier ? Si vous venez à plusieurs, merci de nous préciser avec qui et à combien pour la gestion des tables :</label> <br/>
            {/*<textarea wrap="off" value={allergies} onChange={handleChangeAllergies} style={{ width : "100%"}} placeholder={"Je suis allergie aux cacahuètes."}/>*/}

            <TextField
              id="outlined-multiline-flexible"
              maxRows={4}
              value={comment}
              onChange={handleChangeComment}
              placeholder={"Je suis allergique aux cacahuètes et je viens avec ..."}
              style={{ width : "100%"}}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#a8563a"
                  }
                }
              }}
            />
          </>
          }

          <br/>
          <br/>
          <div className="field">
            {!product.tags.includes("externe") &&
            <button disabled={isFull} className="button has-text-terracota is-link" style={{ marginTop: "20px", backgroundColor: !isFull ? "black" : "grey" }} onClick={addToCart}>
              Ajouter au panier
            </button>
            }

            {placesAddedToCart &&
            <button className="button has-text-terracota is-link" style={{ marginLeft: "1rem", backgroundColor: 'black', marginTop : "20px"}} onClick={() => navigate('/cart')}>
              Voir le panier
            </button>
            }
          </div>
        </div>
      </div>
    </>
  )
}

AtelierReservation.propTypes = {
  data: PropTypes.shape({
    allShopifyProduct: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default ({product, id}) => (
  <StaticQuery
    query={graphql`
  query AtelierInfoQuery {
    allShopifyProduct
     {
      edges {
        node {
          id
          totalVariants
          tracksInventory
          totalInventory
          shopifyId
          description
          descriptionHtml
          variants {
            shopifyId
            title
          }
          featuredImage {
            src
            height
            originalSrc
            width
            altText
            gatsbyImageData
          }
          collections {
            handle
            description
            title
          }
          handle
          tags
          title
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
      sum(field: createdAt)
    }
  }
`}
    render={(data, count) => {
      // Return only atelier with specific ID
      let newData = JSON.parse(JSON.stringify(data))
      newData.allShopifyProduct.edges = null
      let postFiltre = data.allShopifyProduct.edges.filter(obj => {
        if(obj.node.id === id) {
          return obj
        }
      })
      if(postFiltre.length > 0) {
        newData.allShopifyProduct.edges = postFiltre
        return (
          <AtelierReservation product={product} data={newData} />
        )
      }
    }}

    // render={(data) => <AtelierInfo product={product} data={data} />}
  />
)
