import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate, StaticQuery } from "gatsby";
import useStore from "../../context/StoreContext";
import { GatsbyImage } from "gatsby-plugin-image";
import { getProduct } from "../../services/products";
import paragraphs from "lines-to-paragraphs";

const CarteCadeauReservation = (props)  => {
  const { cart } = useStore()

  const { data, product, id } = props
  const productDynamic = data.allShopifyProduct.edges[0].node

  const { addVariantToCart } = useStore()

  const [placesAddedToCart, setPlacesAddedToCart] = useState(false)

  const [indexVariantSelected, setIndexVariantSelected] = useState(0)

  const addToCart = (e) => {
    addVariantToCart(productDynamic, 1, indexVariantSelected).then(() => {
      setPlacesAddedToCart(true)
    })
  }

  const handleVariantChange = (price, index) => {
    setIndexVariantSelected(index)
  };

  return (
    <>
      <div className="columns">
        <div className="column is-4" style={{maxHeight: "520px"}}>
          {product.featuredImage ? (
            <GatsbyImage
              image={product.featuredImage.gatsbyImageData}
              alt={product.featuredImage.altText}
              // height={"250px"}
              imgStyle={{maxHeight: "450px"}}
            />
          ) : null}
        </div>
        <div className="column is-6 is-flex-direction-column is-flex">
          <div>
            <h3>{product.title}</h3>
          </div>
          <div style={{marginBottom: 20}}>
            <div dangerouslySetInnerHTML={{ __html: paragraphs(productDynamic.descriptionHtml) }} style={{ marginTop: 10 }} />
          </div>


          {!product.tags.includes("externe") &&

          <div className="">
            <div className="number-input align--center-center mt--1">
                    <span className="ml--1 mr--1" data-testid="count-status">

                      {product.variants.map((variant, index) => {
                        return (
                          <button className="button has-text-terracota is-link"
                                  style={{ marginRight : "10px", backgroundColor: indexVariantSelected == index  ? "#A8563A" : "black"}}
                                  onClick={() => handleVariantChange(variant.price, index)}
                          >
                            <label> {variant.price} €</label>
                          </button>
                        )

                      })}

                    </span>
            </div>
          </div>
          }
          {placesAddedToCart &&
            <p className="is-size-6" style={{ color: "#085229", fontStyle: "italic", marginTop: "1rem" }}> Produit ajouté au panier !</p>
          }

          <div className="field">

            {!product.tags.includes("externe") &&

            <button className="button has-text-terracota is-link" style={{ marginTop: "20px", backgroundColor: "black" }} onClick={addToCart}>
              Ajouter au panier
            </button>

            }




            {placesAddedToCart &&
            <button className="button has-text-terracota is-link" style={{ marginLeft: "1rem", backgroundColor: 'black', marginTop : "20px"}} onClick={() => navigate('/cart')}>
              Voir le panier
            </button>
            }
          </div>
          <div>
          </div>
        </div>
      </div>
    </>
  )
}

CarteCadeauReservation.propTypes = {
  data: PropTypes.shape({
    allShopifyProduct: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default ({product, id}) => (
  <StaticQuery
    query={graphql`
  query CarteCadeauInfoQuery {
    allShopifyProduct
     {
      edges {
        node {
          id
          totalVariants
          tracksInventory
          totalInventory
          shopifyId
          description
          descriptionHtml
          variants {
            shopifyId
            title
            price
          }
          featuredImage {
            src
            height
            originalSrc
            width
            altText
            gatsbyImageData
          }
          collections {
            handle
            description
            title
          }
          handle
          tags
          title
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
      sum(field: createdAt)
    }
  }
`}
    render={(data, count) => {
      // Return only atelier with specific ID
      let newData = JSON.parse(JSON.stringify(data))
      newData.allShopifyProduct.edges = null
      let postFiltre = data.allShopifyProduct.edges.filter(obj => {
        if(obj.node.id === id) {
          return obj
        }
      })
      if(postFiltre.length > 0) {
        newData.allShopifyProduct.edges = postFiltre
        return (
          <CarteCadeauReservation product={product} data={newData} />
        )
      }
    }}

  />
)
