import React, { } from "react";
import Layout from "../components/layout/Layout"
import AtelierReservation from "../components/atelier/AtelierReservation";
import CarteCadeauxReservation from "../components/gift-card/CarteCadeauxReservation";
import CeramiqueReservation from "../components/ceramique/CeramiqueReservation";
import ReservationClosedBanner from "../components/ReservationClosedBanner";

export const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext

  const getAddedText = () => {
    if (product.collections.length > 0) {
      if (product.collections[0].title.toLowerCase().includes("emma".toLowerCase())) {
        return (
          <>
            <p style={{fontWeight : "bold"}}>Venez faire de la cuisine avec Emma, cheffe cuistot de l’atelier forma.</p>
            <p>Selon les thèmes, vous pourrez vous atteler aux techniques des brioches roulées, produit phare de notre comptoir/café (réalisation de la pâte à brioche, garnissage
              et façonnage des roulés), ou réaliser des salades plus originales et gourmandes les unes que les autres.</p>
            <p>Les thèmes évoluent au fil des saisons : un repas très frais
              de A à Z parfait pour l’été, cupcakes salés ou sucrés de Noël… À chaque fois repartez avec vos créations pour régaler vos proches !</p>
            <p>Possibilité de privatisation pour un minimum de 5 personnes et un maximum de 10 : partager un moment original et créatif en famille ou entre amis !</p>
            <p>Pour offrir cet atelier, pensez aux <a href="/ateliers/test-carte-cadeau">carte cadeaux ! </a></p>
          </>
        )
      } else if (product.collections[0].title.toLowerCase().includes("Lisa".toLowerCase())) {
        return (
          <>
            <p style={{fontWeight : "bold"}}>Créez un objet en céramique avec Lisa, céramiste grenobloise, installée à l’atelier forma.</p>
            <p>Apprenez avec elle à modeler la terre selon différentes techniques (technique de la bande, de la pastille, de la plaque, …), choisissez votre émail parmi sa sélection/ses créations, revenez 2 à 3 semaines plus tard récupérer votre pièce émaillée et cuite par ses soins.</p>
            <p>Si ce cours vous a plu : aller plus loin dans la pratique en prenant une carte 5 ou 10 cours, vous aurez alors la possibilité de réaliser les pièces de votre choix de manière plus libre et créative et de pratiquer le décor et l’émaillage vous-même.</p>
            <p>Possibilité de privatisation pour un minimum de 5 personnes et un maximum de 7 : partager un moment original et créatif en famille ou entre amis !</p>
            <p>Pour offrir cet atelier, pensez aux <a href="/ateliers/test-carte-cadeau">carte cadeaux ! </a></p>
          </>
        )
      } else if (product.collections[0].title.toLowerCase().includes("coline".toLowerCase())) {
        return (
          <>
            <p style={{fontWeight : "bold"}}>Restaurez un meuble chiné avec Coline, chineuse passionnée et bricoleuse avertie.</p>
            <p>Choisissez un petit meuble chiné parmi sa sélection de la semaine et laissez-vous guider dans les technique de restauration (ponçage, customization,…). Une sélection de peinture de la marque bilboquet déco est à votre disposition pour pimper votre meuble.
              Laissez-le sécher et repassez le chercher, vous serez fiers de dire que c’est vous qui l’avez restauré ! </p>
            <p>Possibilité de privatisation pour 4 personnes minimum et maximum : partager un moment original et créatif en famille ou entre amis !</p>
            <p>Pour offrir cet atelier, pensez aux <a href="/ateliers/test-carte-cadeau">carte cadeaux ! </a></p>
          </>
        )
      } else if (product.collections[0].title.toLowerCase().includes("Séverine".toLowerCase())) {
        return (
          <>
            <p style={{fontWeight : "bold"}}>Pour multiplier les thématiques et les dates nous faisons appel à une céramiste locale que nous aimons beaucoup et qui est présente sur les étagères de notre boutique : Séverine Raphoz de OZ céramique.</p>
            <p>Passionnée et pédagogue, elle vous amènera à développer toute votre créativité. Façonnez des pièces variées grâce aux différentes techniques de modelage et décorez-les en impression. Elles seront émaillées en blanc par Séverine et vous pourrez les récupérer le mois suivant. </p>
          </>
        )
      } else if (product.collections[0].title.toLowerCase().includes("Nelly".toLowerCase())) {
        return (
          <>
            <p style={{fontWeight : "bold"}}>Essayez-vous à la linogravure avec notre illustratrice préférée, Nelly de "un caillou dans la chaussure". </p>
            <p>Venez avec vos inspirations/dessins/idées et lancez-vous dans la gravure de votre plaque de lino. Encrez-la ensuite, en choisissant parmi 3 couleurs proposées, et pressez votre plaque sur un papier adapté, de sorte à faire ressortir votre dessin en négatif. Vous serez guidés tout au long de ces 3h d’atelier.</p>
            <p>Repartez avec vos linogravures en plusieurs exemplaires  pour les afficher fièrement chez vous ou les offrir.</p>
            <p>Pour offrir cet atelier, pensez aux <a href="/ateliers/test-carte-cadeau">carte cadeaux ! </a></p>
          </>
        )
      }
    }
  }


  const getProductPage = () => {
    if (product.handle === "brunch-ceramique-1" || product.handle === "creneau-classique-ceramique"  || product.handle === "creneau-express-ceramique" || product.handle === "dej-gouter-ceramique") {
      return <CeramiqueReservation product={product} id={product.id} />
    } else if (product.handle.includes("cadeau")) {
      return <CarteCadeauxReservation product={product} id={product.id} />
    } else {
      return <AtelierReservation product={product} id={product.id} />
    }
  }

  return (
    <Layout>
      <div className="content">
        <section className="section section--gradient" style={{ textAlign: 'justify' }}>

          <div className="container content">

            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {product.title}
            </h1>
            <article className={`is-child box atelier-list-item`}>
              { getProductPage()}
              <div>
                {getAddedText()}
              </div>
            </article>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ProductTemplate
